import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import ImageSquare from 'src/components/common/image-square';
import { ImageWide } from 'src/components/common/image-wide';

type TeaserImageProps = PropsWithChildren<{
  src?: string;
  placeholder?: 'artist' | 'group' | 'partner';
  playable?: boolean;
  darken?: boolean;
  variant: 'round' | 'square' | 'wide';
  alt: string;
}>;

/**
 * Maps a teaser image type to an image source size suggestion for the browser based on a media query.
 * The browser will then choose the best image source based on the available image sizes defined in the `srcSet`
 * attribute. If we don’t do this, the browser will automatically choose an image source based on the viewport width.
 *
 * Teasers – and therefore the teaser images – never take up the full width of the viewport however and are fully
 * responsive. This means that if we don’t provide a size suggestion, the browser will load the image source that is
 * closest to the viewport width (100vw) which will have a negative impact on page performance.
 *
 * To handle this, we will try to make a rough estimate of the width of the teaser image based on the number of
 * columns the teaser takes up on the grid.
 *
 * +-------------+--------------+-------------+------------+
 * | Teaser Type | lg (>1024px) | md (>768px) | default    |
 * +-------------+--------------+-------------+------------+
 * | wide        | 4/12 col     | 8/12 col    | 12/12 col  |
 * | square      | 3/12 col     | 4/12 col    | 6/12 col   |
 * | round       | 2/12 col     | 3/12 col    | 6/12 col   |
 * +-------------+--------------+-------------+------------+
 *
 * Based on this, we define a list of source sizes with pixel values that correspond with the values defined in the
 * `images.imageSizes` and `images.deviceSizes` arrays in `next.config.js`. These values can be fine-tuned over time.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-sizes
 *
 * @note We cap pixel density to 2x resolution because showing a 2x image on a 3x device won’t look any different to
 * the naked eye but it will have a positive impact on page performance. Twitter discusses this in a blog post:
 * https://blog.twitter.com/engineering/en_us/topics/infrastructure/2019/capping-image-fidelity-on-ultra-high-resolution-devices.html
 *
 * To calculate the 3x value, we take the normal value and multiply it by 2/3.
 * For example, if the normal value is 512px, the 3x value should be 512px * 2/3 = 341px
 */
const teaserImageVariantSizesMap: Record<TeaserImageProps['variant'], string> = {
  wide: [
    '(min-width: 480px) and (min-resolution: 3dppx) calc(512px * 2/3)',
    '(min-width: 480px) 512px',
    '(min-resolution: 3dppx) calc(256px * 2/3)',
    '256px',
  ].join(', '),
  square: [
    '(min-width: 1280px) and (min-resolution: 3dppx) calc(320px * 2/3)',
    '(min-width: 1280px) 320px',
    '(min-width: 480px) and (min-resolution: 3dppx) calc(256px * 2/3)',
    '(min-width: 480px) 265px',
    '(min-resolution: 3dppx) calc(128px * 2/3)',
    '128px',
  ].join(', '),
  round: [
    '(min-width: 480px) and (min-resolution: 3dppx) calc(256px * 2/3)',
    '(min-width: 480px) 265px',
    '(min-resolution: 3dppx) calc(128px * 2/3)',
    '128px',
  ].join(', '),
};

/**
 * Returns a teaser image for a given node
 * There are 3 kinds of teaser images:
 * - Wide: 16:9 aspect ratio (Epoch, Genre, LiveConcert, Video, and VodConcert)
 * - Square: 1:1 aspect ratio (Album)
 * - Round: 1:1 aspect ratio but as a circle (Artist, Group, and Partner)
 *
 * All teaser images are responsive and lazy loaded.
 * Note the `sizes` attribute which is used to help the browser pick the best image size for the current viewport.
 */
export function TeaserImage({
  src,
  alt,
  placeholder,
  playable = false,
  darken = false,
  variant,
  children,
}: TeaserImageProps) {
  return (
    <div
      className={clsx(
        'relative overflow-hidden',
        // Use `:before` pseudo element to display a play icon on top of the teaser image
        playable &&
          "before:absolute before:inset-0 before:z-10 before:scale-50 before:bg-[url('/images/play-overlay.svg')] before:bg-[length:75%_75%] before:bg-center before:bg-no-repeat before:opacity-0 before:transition-all before:duration-300 before:ease-in-out lg:before:bg-[length:50%_50%] before:mouse:group-hover:scale-100 before:mouse:group-hover:opacity-100",
        // Use `:after` pseudo element to display a dark overlay on top of the teaser image
        darken && 'after:absolute after:inset-0 after:bg-black/15',
        variant === 'round' && 'rounded-full',
        variant === 'square' && 'rounded',
        variant === 'wide' && 'rounded',
      )}
    >
      {variant === 'wide' ? (
        <ImageWide fill src={src} alt={alt} sizes={teaserImageVariantSizesMap[variant]} />
      ) : (
        <ImageSquare fill src={src} alt={alt} sizes={teaserImageVariantSizesMap[variant]} placeholder={placeholder} />
      )}
      {children && <div className="absolute inset-0 z-10 p-2 lg:p-4">{children}</div>}
    </div>
  );
}
