import Image from 'next/image';
import fourKSource from 'public/images/landing-page/4k-ultra-hd.png';
import dolbySource from 'public/images/landing-page/dolby.png';
import losslessSource from 'public/images/landing-page/lossless.png';

import ContainerGrid from 'src/components/container-grid';
import useTranslate from 'src/hooks/use-translate';

const computerSource = '/images/landing-page/computer.svg';
const mobileTabletSource = '/images/landing-page/mobile-tablet.svg';
const tvSource = '/images/landing-page/tv.svg';

// This is a block on a landing page that features the various platform types
// and platforms where Stage+ is available, as well as supported audio and video
// qualities.
export default function MarketingDevices() {
  const t = useTranslate();
  return (
    <div className="pb-28 pt-16 lg:pb-40" data-test="marketing-devices-module">
      <h1 className="dg-text-medium-1 mb-10 text-center lg:mb-12">{t('marketing_devices__headline')}</h1>
      <div className="mb-10 md:mb-[72px]">
        <ContainerGrid>
          <div className="col-span-full grid gap-6 text-center md:grid-flow-col md:grid-cols-3 lg:gap-7">
            <div className="bg-highlightBlueC3 p-6 pb-8">
              <Image
                src={computerSource}
                alt=""
                role="presentation"
                width="130"
                height="97"
                className="m-auto mb-6 w-[65px] md:w-[130px]"
              />
              <h2 className="mb-2.5 text-xl md:text-2xl">{t('marketing_devices__computer')}</h2>
              <ul className="dg-text-medium-6 text-greyG2">
                <li>{t('marketing_devices__computer_windows')}</li>
                <li>{t('marketing_devices__computer_macos')}</li>
                <li>{t('marketing_devices__computer_chrome')}</li>
              </ul>
            </div>
            <div className="bg-highlightBlueC3 p-6 pb-8">
              <Image
                src={tvSource}
                alt=""
                role="presentation"
                width="116"
                height="97"
                className="m-auto mb-6 w-[58px] md:w-[116px]"
              />
              <h2 className="mb-2.5 text-xl md:text-2xl">{t('marketing_devices__tv')}</h2>
              <ul className="dg-text-medium-6 text-greyG2">
                <li>{t('marketing_devices__tv_amazon')}</li>
                <li>{t('marketing_devices__tv_android')}</li>
                <li>{t('marketing_devices__tv_apple')}</li>
              </ul>
            </div>
            <div className="bg-highlightBlueC3 p-6 pb-8">
              <Image
                src={mobileTabletSource}
                alt=""
                role="presentation"
                width="119"
                height="97"
                className="m-auto mb-6 w-[60px] md:w-[119px]"
              />
              <h2 className="mb-2.5 text-xl md:text-2xl">{t('marketing_devices__mobile')}</h2>
              <ul className="dg-text-medium-6 text-greyG2">
                <li>{t('marketing_devices__mobile_iphone')}</li>
                <li>{t('marketing_devices__mobile_ipad')}</li>
                <li>{t('marketing_devices__mobile_browsers')}</li>
              </ul>
            </div>
          </div>
        </ContainerGrid>
      </div>
      <div className="flex justify-center gap-5 md:gap-[53px]">
        <Image src={dolbySource} alt="Dolby Atmos" className="w-[80px] md:w-[110px]" />
        <Image src={losslessSource} alt="Lossless" className="w-[83px] md:w-[115px]" />
        <Image src={fourKSource} alt="4K UltraHD" className="w-[47px] md:w-[66px]" />
      </div>
    </div>
  );
}
