import clsx from 'clsx';
import Link from 'next/link';
import { MDXRemote, type MDXRemoteProps } from 'next-mdx-remote';
import ContainerGrid from 'src/components/container-grid';
import { SeoSection } from 'src/utilities/seo-helpers';
import { getAbsoluteUrl } from 'src/utilities/url-helpers';

/**
 * Components that are used to customize the MarkDown rendering
 */
const markdownComponents: MDXRemoteProps['components'] = {
  /**
   * Markdown links are allowed with some restrictions:
   * - External links should always be opened in a new tab with rel="nofollow"
   * - Internal links should be handled by Next.js
   */
  a: ({ href, children }) => {
    const className = 'focus-visible:focus-outline text-textPrimary underline hover:text-textSecondary';

    // If the link is internal, use the router
    if (href?.startsWith('/')) {
      return (
        <Link href={getAbsoluteUrl(href)} prefetch={false} className={className}>
          {children}
        </Link>
      );
    }

    // Otherwise the link is external and should be opened in a new tab
    return (
      <a href={href} target="_blank" rel="nofollow noreferrer" className={className}>
        {children}
      </a>
    );
  },
  h1: ({ children, ...rest }) => (
    // Render first-level headings as h1 visually but using <h2> tag to prevent having multiple h1s on the page.
    // This way we can keep a more correct document outline with a single h1 at the beginning of the page,
    // as it's essential for good accessibility.
    <h2 className="dg-text-medium-1 mb-4 text-textSecondary" {...rest}>
      {children}
    </h2>
  ),
  h2: ({ children, ...rest }) => (
    <h2 className="dg-text-medium-2 mb-4 text-textSecondary" {...rest}>
      {children}
    </h2>
  ),
  h3: ({ children, ...rest }) => (
    <h3 className="dg-text-medium-3 mb-4 text-textSecondary" {...rest}>
      {children}
    </h3>
  ),
  p: (props) => <p className="dg-text-regular-5 mb-4 text-textTertiary">{props.children}</p>,
};

type SeoFooterProps = {
  sections: SeoSection[];
};

export function SeoFooter({ sections }: SeoFooterProps) {
  return (
    <div className="my-14 text-left" data-test="seo-footer">
      <ContainerGrid>
        <section className="col-span-full whitespace-normal border-t border-surface-500 pt-14">
          {sections.map(({ mdx, children }, index) => (
            // The list is static, so we can safely use index as a key
            <article key={index} className="mb-8">
              <div className="mb-6">
                <MDXRemote {...mdx} components={markdownComponents} />
              </div>
              <ContainerGrid>
                {children.length > 0 &&
                  children.map(({ mdx: childMdx }, index) => (
                    <div
                      key={index}
                      className={clsx(
                        // 1 column will take the whole width
                        // also on smaller screens every child will take the whole width
                        'col-span-12',
                        // on larger screens, take a fraction of the width based on the number of children
                        children.length === 2 && 'lg:col-span-6',
                        children.length === 3 && 'lg:col-span-4',
                        children.length === 4 && 'lg:col-span-3',
                      )}
                    >
                      <MDXRemote {...childMdx} components={markdownComponents} />
                    </div>
                  ))}
              </ContainerGrid>
            </article>
          ))}
        </section>
      </ContainerGrid>
    </div>
  );
}
